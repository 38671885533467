import request from '@/utils/requestv2'
import { stringify } from 'qs'

/*
 * 出货订单列表分页
 */
export async function PageDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 出货订单详情
 */
export async function DetailDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 出货订单新增
 */
export async function SaveDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 出货订单修改
 */
export async function UpdateDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 出货订单删除
 */
export async function DeleteDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 出货订单批量删除
 */
export async function DeleteMultipleDeviceOrder(data) {
  return request({
    url: '/admin/smart/device/order/batchDelete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 通过名称获取商户列表
 */
export async function FindShopByName(data) {
  return request({
    url: '/admin/merchant/shopList/findShopByName',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 智能设备列表分页
 */
export async function PageDevices(data) {
  return request({
    url: '/admin/smart/device/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 智能设备列表不分页
 */
export async function ListDevices(data) {
  return request({
    url: '/admin/smart/device/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 智能设备删除
 */
export async function DeleteDevices(data) {
  return request({
    url: '/admin/smart/device/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 智能设备新增
 */
export async function SaveDevices(data) {
  return request({
    url: '/admin/smart/device/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}
