import request from '@/utils/requestv2'
import { stringify } from 'qs'

/*
 * 新增设备类型
 */
export async function SelectDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 新增设备类型
 */
export async function SaveDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 修改设备类型
 */
export async function UpdateDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除设备类型
 */
export async function DeleteDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除多个设备类型
 */
export async function DeleteMultipleDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/batchDelete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备类型分页
 */
export async function PageDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备类型详情
 */
export async function DetailDeviceType(data) {
  return request({
    url: '/admin/smart/device/type/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 设备电量充值
 */
export async function RechargeDeviceType(data) {
  return request({
    url: '/admin/smart/device/recharge',
    method: 'post',
    data,
  })
}
