<template>
  <div>
    <el-dialog
      :before-close="onClose"
      :close-on-click-modal="false"
      :title="title"
      :visible="visible"
      :width="width"
    >
      <div class="container" :style="{ height: height + 'px' }">
        <slot name="content"></slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="
            title == '新增订单' ||
            title == '新增设备类型' ||
            title == '新增设备品牌' ||
            title == '新增设备型号'
          "
          @click="handleClose"
        >
          取 消
        </el-button>
        <el-button
          v-if="
            title == '新增订单' ||
            title == '新增设备类型' ||
            title == '新增设备品牌' ||
            title == '新增设备型号'
          "
          type="primary"
          @click="handleConfirm"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'Dialog',
    props: {
      title: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: false,
      },
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: '500px',
      },
      height: {
        type: Number,
        default: 250,
      },
    },
    mounted() {},
    methods: {
      // 确定新增/修改
      handleConfirm() {
        this.$emit('handleConfirm')
      },
      // 取消
      handleClose() {
        this.$emit('handleCloseDialog')
      },
      onClose() {
        this.$emit('handleCloseDialog')
      },
    },
  }
</script>

<style lang="scss">
  .container {
    overflow-x: initial;
    overflow-y: auto;
  }
  .el-dialog {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    .el-dialog__header {
      margin-right: 0;
      background-color: #1890ff !important;
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;
      .el-dialog__title {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
      .el-dialog__close {
        color: #fff !important;
      }
    }
    .el-dialog__body {
      padding: 10px;
    }
    .el-dialog__footer {
      padding: 10px;
      border-top: 1px solid #e8eaec !important;
    }
    .el-icon svg {
      color: #fff;
    }
  }
</style>
