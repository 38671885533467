import request from '@/utils/requestv2'
import { stringify } from 'qs'

/**
 * 商户设备绑定信息
 */
export async function PageShopBindDevice(data) {
  return request({
    url: '/admin/merchant/shopList/pageShopBindDevice',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 商户房源设备绑定记录列表
 */
export async function PageShopBindLogDevice(data) {
  return request({
    url: '/admin/smart/device/bindLog/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 商户楼栋列表
 */
export async function ListBuilding(data) {
  return request({
    url: '/admin/house/building/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 商户楼栋房源设备列表
 */
export async function HouseDeviceList(data) {
  return request({
    url: '/admin/house/building/houseDeviceList',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 商户房源抄表记录列表
 */
export async function PageHouseMeterRecord(data) {
  return request({
    url: '/admin/house/meter/pageHouseMeterRecord',
    method: 'post',
    data: stringify({ ...data }),
  })
}
