<template>
  <div class="upload-container">
    <el-upload
      :action="domain"
      :before-upload="beforeUpload"
      class="image-uploader"
      :data="QiniuData"
      :file-list="fileList"
      :limit="number"
      :list-type="fileType"
      :multiple="multiple"
      :on-change="handleChange"
      :on-exceed="onExceed"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleImageSuccess"
    >
      <i v-if="fileType !== 'text'" class="el-icon-upload" />
      <el-button v-else type="text">点击上传</el-button>
    </el-upload>
    <!-- <el-image-viewer
                                      v-if="dialogVisible"
                                      :url-list="[dialogImageUrl]"
                                    /> -->
    <el-dialog append-to-body title="查看大图" :visible.sync="dialogVisible">
      <img alt="" :src="dialogImageUrl" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
  import { getUploadToken } from '@/api/common/index'
  // import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  const uploadUrlConf = {
    'qiniu.region.z0': '//upload.qiniup.com',
    'qiniu.region.z2': '//upload-z2.qiniup.com',
    'qiniu.region.z1': '//upload-z1.qiniup.com',
  }
  const uidGenerator = () => {
    return '-' + parseInt(Math.random() * 10000 + 1, 10)
  }
  export default {
    name: 'JMUpload',
    // components: { ElImageViewer },
    props: {
      value: {
        type: String,
        default: '',
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      number: {
        type: Number,
        required: false,
        default: 0,
      },
      fileType: {
        type: String,
        default: 'picture-card',
      },
    },
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        fileList: [],
        domain: '', // 七牛云的上传地址（华东区）
        tempUrl: '',
        name: '',
        dataObj: { token: '', key: '' },
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
      }
    },
    computed: {
      imageUrl() {
        console.log('this.value', this.value)
        return this.value
      },
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          this.fileList = []
          console.log('this.value', this.value)
          if (this.value) {
            this.fileList.push({
              uid: uidGenerator(),
              url: this.value,
              name: this.value,
            })
          }
        },
      },
    },
    methods: {
      handlePictureCardPreview(file) {
        if (this.fileType === 'text') {
          return
        }
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleChange(v) {
        console.log('v1', v)
        console.log('this.tempUrl', this.tempUrl)
        this.$emit('change', this.tempUrl)
      },
      handleRemove() {
        //this.rmImage()
      },
      rmImage() {
        this.emitInput('')
      },
      emitInput(val) {
        this.$emit('input', val)
      },
      handleImageSuccess(v2) {
        console.log(
          '🚀 ~ file: jm-upload.vue:126 ~ handleImageSuccess ~ v2',
          v2
        )
        this.emitInput(this.tempUrl)
      },
      async beforeUpload(file) {
        console.log('iron.guo create upload file start >>>>')
        const fileName = file.name || file.path
        let ext = fileName.substring(
          fileName.lastIndexOf('.') + 1,
          fileName.length
        )
        let res = await getUploadToken({
          extension: ext,
        })
        if (res.code === 0) {
          this.domain = uploadUrlConf[res.data.region]
          this.QiniuData = {
            data: file,
            token: res.data.token,
            key: res.data.key,
          }
          this.tempUrl = res.data.url
          console.log(res.data.url, 'res.data.url==>')
          this.handleChange()
          return true
        }
      },
      onExceed() {
        this.$message({
          message: '最多可上传' + this.number + '张图片',
          type: 'error',
          center: true,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .upload-container {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    .image-uploader {
      width: 60%;
      float: left;
    }

    .image-preview {
      width: 200px;
      height: 200px;
      position: relative;
      border: 1px dashed #d9d9d9;
      float: left;
      margin-left: 50px;

      .image-preview-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .image-preview-action {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: default;
        text-align: center;
        color: #fff;
        opacity: 0;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        cursor: pointer;
        text-align: center;
        line-height: 200px;

        .el-icon-delete {
          font-size: 36px;
        }
      }

      &:hover {
        .image-preview-action {
          opacity: 1;
        }
      }
    }
  }
</style>
