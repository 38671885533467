import requestV2 from '@/utils/requestv2'

// 小区管理列表（分页）
export async function getCommunityListApi(data) {
  return requestV2({
    url: '/admin/community/management/page',
    method: 'post',
    data: data,
  })
}

// 小区管理名称 kv
export async function getCommunityNameApi(name) {
  return requestV2({
    url: '/admin/community/management/getCommunityName',
    method: 'post',
    data: { name: name },
  })
}

// 删除小区
export async function deleteCommunityApi(data) {
  return requestV2({
    url: '/admin/community/management/delete',
    method: 'post',
    data: data,
  })
}

// 新增小区
export async function addCommunityApi(data) {
  return requestV2({
    url: '/admin/community/management/save',
    method: 'post',
    data: data,
  })
}

// 编辑小区
export async function editCommunityApi(data) {
  return requestV2({
    url: '/admin/community/management/update',
    method: 'post',
    data: data,
  })
}
