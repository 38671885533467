import request from '@/utils/requestv2'
import { stringify } from 'qs'

/**
 * 设备品牌下拉
 */
export async function SelectDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 新增设备品牌
 */
export async function SaveDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

/**
 * 修改设备品牌
 */
export async function UpdateDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除设备品牌
 */
export async function DeleteDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除设备品牌
 */
export async function DeleteMultipleDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/batchDelete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备品牌分页
 */
export async function PageDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备品牌详情
 */
export async function DetailDeviceBrand(data) {
  return request({
    url: '/admin/smart/device/brand/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
