import request from '@/utils/requestv2'

export function vipList(params) {
  return request({
    url: '/admin/shop/member/page',
    method: 'post',
    params,
  })
}

export function payRecordList(params) {
  return request({
    url: '/admin/wallet/record/page',
    method: 'post',
    params,
  })
}

export function rechargeList(params) {
  return request({
    url: '/admin/wallet/recharge/order/page',
    method: 'post',
    params,
  })
}

export function billList(params) {
  return request({
    url: '/admin/shop/invoice/page',
    method: 'post',
    params,
  })
}

export function costList(params) {
  return request({
    url: '/admin/shop/fee/config/page',
    method: 'post',
    params,
  })
}

export function costDetail(params) {
  return request({
    url: '/admin/shop/fee/config/detail',
    method: 'get',
    params,
  })
}

export function costAdd(params) {
  return request({
    url: '/admin/shop/fee/config/add',
    method: 'post',
    params,
  })
}

export function costDel(params) {
  return request({
    url: '/admin/shop/fee/config/delete',
    method: 'post',
    params,
  })
}

export function costReset(params) {
  return request({
    url: '/admin/shopList/resetCostFeeConfig',
    method: 'post',
    params,
  })
}

export function costUpdate(params) {
  return request({
    url: '/admin/shop/fee/config/update',
    method: 'post',
    params,
  })
}
/** 保存商户费用配置 */
export function saveCostConfig(params) {
  return request({
    url: '/admin/shop/fee/config',
    method: 'post',
    data: params,
  })
}

/** 修改商户费用配置 */
export function putCostConfig(params) {
  return request({
    url: '/admin/shop/fee/config',
    method: 'put',
    data: params,
  })
}

/** 获取商户费用配置 */
export function getCostConfig(params) {
  return request({
    url: '/admin/shop/fee/config',
    method: 'get',
    params,
  })
}
export function rateList(params) {
  return request({
    url: '/admin/shop/business/page',
    method: 'post',
    params,
  })
}

export function rateDetail(params) {
  return request({
    url: '/admin/shop/business/detail',
    method: 'get',
    params,
  })
}

export function rateAdd(params) {
  return request({
    url: '/admin/shop/business/add',
    method: 'post',
    params,
  })
}

export function rateDel(params) {
  return request({
    url: '/admin/shop/business/delete',
    method: 'post',
    params,
  })
}

export function rateUpdate(params) {
  return request({
    url: '/admin/shop/business/update',
    method: 'post',
    params,
  })
}

// 商户参数设置查询
export function paramList(params) {
  return request({
    url: '/admin/shop/param/setting/findAllShopParamSetting',
    method: 'get',
    params,
  })
}

// 商户参数配置重置
export function paramReset(params) {
  return request({
    url: '/admin/shop/param/setting/reset',
    method: 'post',
    params,
  })
}

// 商户参数配置修改
export function paramUpdate(params) {
  return request({
    url: '/admin/shop/param/setting/update',
    method: 'post',
    params,
  })
}
