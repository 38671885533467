import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function memberServiceOrderList(data) {
  return request({
    url: '/admin/member/service/order/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
