import request from '@/utils/requestv2'
// 获取用户列表
export function getUserAPI(data) {
  return request({
    url: '/admin/sys/users',
    method: 'get',
    params: data,
  })
}

//保存用户
export function addUserAPI(data) {
  return request({
    url: '/admin/sys/users',
    method: 'post',
    data: data,
  })
} // 修改用户
export function updateUserAPI(data) {
  return request({
    url: '/admin/sys/users',
    method: 'put',
    data: data,
  })
} // 删除用户
export function deleteUserAPI(data) {
  return request({
    url: `/admin/sys/users/${data}`,
    method: 'delete',
  })
} // 修改用户角色列表
export function updateRolesAPI(data, userId) {
  return request({
    url: `/admin/sys/users/${userId}/roles`,
    method: 'put',
    data: data,
  })
} // 修改用户密码
export function putPwdAPI(userId, password) {
  return request({
    url: `/admin/sys/users/${userId}/${password}`,
    method: 'put',
  })
}
