import request from '@/utils/requestv2'
import qs from 'qs'

// 分佣明细列表
export function getChannelCommissionPage(data) {
  return request({
    url: '/admin/channel/commission/page',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 分佣明细详情
export function getChannelCommissionDetail(id) {
  return request({
    url: '/admin/channel/commission/detail',
    method: 'get',
    params: { id },
  })
}

// 月结账单列表
export function getChannelSubtotalPage(data) {
  return request({
    url: '/admin/channel/subtotal/page',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 月结账单详情
export function getChannelSubtotalDetail(id) {
  return request({
    url: '/admin/channel/subtotal/detail',
    method: 'get',
    params: { id },
  })
}

// 结算
export function settleSubtotal(params) {
  return request({
    url: '/admin/channel/subtotal/settle',
    method: 'post',
    data: qs.stringify(params),
  })
}
