import request from '@/utils/requestv2'

// 开发票
export function makeInvoice(params) {
  return request({
    url: '/admin/invoice',
    method: 'post',
    data: params,
  })
}
// 查询开票记录分页列表
export function invoicePage(params) {
  return request({
    url: '/admin/invoice',
    method: 'get',
    params: params,
  })
}
// 发票申请驳回
export function invoiceReject(params) {
  return request({
    url: '/admin/invoice/reject',
    method: 'post',
    data: params,
  })
}

// 查询开票记录详情
export function invoiceDetail(params) {
  return request({
    url: `/admin/invoice/${params}`,
    method: 'get',
  })
}
