import request from '@/utils/requestv2'

export function getSmsOrder(params) {
  return request({
    url: '/admin/sms/order',
    method: 'get',
    params,
  })
}
export function getSmsOrderStatistics(params) {
  return request({
    url: '/admin/sms/order/statistics',
    method: 'get',
    params,
  })
}
