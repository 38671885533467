import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 提交易票联开户
export async function merchantApply(data) {
  return request({
    url: '/admin/merchant/shopList/merchantApply',
    method: 'post',
    data: stringify({ ...data }),
  })
}
export async function updateStatusAPI(data) {
  return request({
    url: '/admin/merchant/shopList/updateStatus',
    method: 'post',
    data: data,
  })
}
// 商户详情
export function shopListDetail(params) {
  return request({
    url: '/admin/merchant/shopList/detail',
    method: 'get',
    params,
  })
}
// 楼栋信息
export function buildingPage(data) {
  return request({
    url: '/admin/house/building/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 合同
export function contractPage(data) {
  return request({
    url: '/admin/house/contract/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 商户配置 账单费用类型
export function billSubjectConfigList(params) {
  return request({
    url: '/admin/merchant/shopList/billSubjectConfigList',
    method: 'post',
    params,
  })
}
// 商户配置 支付方式
export function paymentWayConfigList(params) {
  return request({
    url: '/admin/merchant/shopList/paymentWayConfigList',
    method: 'post',
    params,
  })
}
// 商户配置 通知类型
export function noticeConfigList(params) {
  return request({
    url: '/admin/merchant/shopList/noticeConfigList',
    method: 'post',
    params,
  })
}
// 商户账号
export function shopuserPage(data) {
  return request({
    url: '/admin/merchant/shopuser/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 结算账号信息列表接口
export function shopBankAccountList(data) {
  return request({
    url: '/admin/merchant/shopbank/accountList',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 结算账号信息详情接口
export function shopBankAccountDetail(params) {
  return request({
    url: '/admin/merchant/shopbank/detail',
    method: 'get',
    params,
  })
}
