import request from '@/utils/requestv2'

export function getEsignContractTemplate(params) {
  return request({
    url: '/esign/contract/template',
    method: 'get',
    params,
  })
}

export function addEsignContractTemplate(data) {
  return request({
    url: '/esign/contract/template/create',
    method: 'post',
    data,
  })
}

export function putEsignContractTemplate(data) {
  return request({
    url: `/esign/contract/template/update`,
    method: 'put',
    data,
  })
}

export function getEsignContractTemplateFields(id) {
  return request({
    url: `/esign/contract/template/fields/${id}`,
    method: 'get',
  })
}
export function bindEsignContractTemplateFields(data) {
  return request({
    url: `/esign/contract/template/bind`,
    method: 'post',
    data,
  })
}

export function getEsignContractTemplateBindList(id) {
  return request({
    url: `/esign/contract/template/bind/info/${id}`,
    method: 'get',
  })
}
export function openEsignContractTemplate(id) {
  return request({
    url: `/esign/contract/template/open/${id}`,
    method: 'patch',
  })
}

export function closeEsignContractTemplate(id) {
  return request({
    url: `/esign/contract/template/close/${id}`,
    method: 'patch',
  })
}
export function deleteEsignContractTemplate(id) {
  return request({
    url: `/esign/contract/template/${id}`,
    method: 'delete',
  })
}

export function getEsignContractPackage(params) {
  return request({
    url: `/admin/esign/contract/package`,
    method: 'get',
    params,
  })
}

export function addEsignContractPackage(data) {
  return request({
    url: `/admin/esign/contract/package`,
    method: 'post',
    data,
  })
}

export function putEsignContractPackage(data) {
  return request({
    url: `/admin/esign/contract/package`,
    method: 'put',
    data,
  })
}

export function getEsignContractPackageDetail(id) {
  return request({
    url: `/admin/esign/contract/package/${id}`,
    method: 'get',
  })
}

export function deleteEsignContractPackage(id) {
  return request({
    url: `/admin/esign/contract/package/${id}`,
    method: 'delete',
  })
}
export function switchEsignContractPackage(id) {
  return request({
    url: `/admin/esign/contract/package/switch/status/${id}`,
    method: 'put',
  })
}

export function getEsignContractUsagePage(params) {
  return request({
    url: `/admin/esign/contract/statistics/shop/usage/page`,
    method: 'get',
    params,
  })
}

export function getEsignContractUsage(params) {
  return request({
    url: `/admin/esign/contract/statistics/shop/usage`,
    method: 'get',
    params,
  })
}

export function getEsignContractRemaining(params) {
  return request({
    url: `/admin/esign/contract/statistics/sys/remaining`,
    method: 'get',
    params,
  })
}

export function esignReRealNameAuth(data) {
  return request({
    url: `/api/esign/account/reRealNameAuth`,
    method: 'post',
    data,
  })
}

export function getEsignContractInitiatePage(params) {
  return request({
    url: `/admin/esign/contract/initiate/page`,
    method: 'get',
    params,
  })
}

export function getEsignContractRechargePage(params) {
  return request({
    url: `/admin/esign/contract/recharge/page`,
    method: 'get',
    params,
  })
}

export function getEsignContractInfoUrl(params) {
  return request({
    url: `/admin/esign/contract/info`,
    method: 'get',
    params,
  })
}

export function getEsignContractAlert() {
  return request({
    url: `/admin/esign/contract/alert/sys/threshold`,
    method: 'get',
  })
}

export function putEsignContractAlert(threshold) {
  return request({
    url: `/admin/esign/contract/alert/sys/threshold/${threshold}`,
    method: 'put',
  })
}

export function getEsignContractFileUrl(id) {
  return request({
    url: `/esign/contract/template/download/url?id=${id}`,
    method: 'post',
  })
}

export function getEsignEditUrl(id) {
  return request({
    url: `/esign/contract/template/edit/${id}`,
    method: 'get',
  })
}
