import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 账单推送记录列表（分页）
export async function pushrecordList(data) {
  return request({
    url: '/admin/bill/pushrecord/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 账单详情
export function pushrecordDetail(params) {
  return request({
    url: '/admin/bill/pushrecord/detail',
    method: 'get',
    params,
  })
}
