import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function sendMsgLogList(data) {
  return request({
    url: '/admin/shop/send/msg/log/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
