import request from '@/utils/requestv2'
import qs from 'qs'

// 渠道商列表
export function getDistributorPage(data) {
  return request({
    url: '/admin/channel/distributor/page',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 渠道商详情
export function getDistributorDetail(id) {
  return request({
    url: '/admin/channel/distributor/detail',
    method: 'get',
    params: { id },
  })
}

// 渠道商新增
export function addDistributor(params) {
  return request({
    url: '/admin/channel/distributor/save',
    method: 'post',
    data: qs.stringify(params),
  })
}

// 渠道商更改开启禁用状态
export function updateDistributorStatus(id) {
  return request({
    url: '/admin/channel/distributor/changeStatus',
    method: 'post',
    data: qs.stringify({ id }),
  })
}

// 渠道商关联商户列表
export function getDistributorShopPage(data) {
  return request({
    url: '/admin/channel/distributor/pageDistributorShop',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 二级渠道商列表
export function getDistributorSecondaryPage(data) {
  return request({
    url: '/admin/channel/distributor/pageSecondaryDistributor',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 渠道商修改
export function updateDistributor(params) {
  return request({
    url: '/admin/channel/distributor/update',
    method: 'post',
    data: qs.stringify(params),
  })
}

// 渠道商更改上级
export function updateDistributorSuperior(params) {
  return request({
    url: '/admin/channel/distributor/updateSuperiorDistributor',
    method: 'post',
    data: qs.stringify(params),
  })
}

// 渠道商列表
export function getDistributorList(data) {
  return request({
    url: '/admin/channel/distributor/list',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 移除下级
export function removeSecondaryDistributor(data) {
  return request({
    url: '/admin/channel/distributor/removeSecondaryDistributor',
    method: 'post',
    data: qs.stringify(data),
  })
}
