var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("quill-editor", {
    attrs: { options: _vm.options },
    model: {
      value: _vm.myValue,
      callback: function ($$v) {
        _vm.myValue = $$v
      },
      expression: "myValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }