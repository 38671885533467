import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function roleList() {
  return request({
    url: '/admin/member/info/getMemberRoleRoleList',
    method: 'post',
  })
}

// 会员详情
export function detail(id) {
  return request({
    url: '/admin/member/info/detail',
    method: 'post',
    data: stringify({ id }),
  })
}

// 角色设置保存
export function updateMember(data) {
  return request({
    url: '/admin/member/info/setMemberRole',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export function syncWxUser() {
  return request({
    url: '/admin/wx/users/sync',
    method: 'post',
  })
}
