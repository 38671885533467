import request from '@/utils/requestv2'
// 房源审核（分页）
export async function getHousePublish(data) {
  return request({
    url: '/house/publish',
    method: 'get',
    params: data,
  })
}
// 获取分享图片
export async function houseShareImgCode(data) {
  return request({
    url: '/zss-house-share-img-record',
    method: 'get',
    params: data,
  })
}
// 保存分享图片
export async function addHouseShareImgCode(data) {
  return request({
    url: '/zss-house-share-img-record',
    method: 'post',
    data: data,
  })
}
