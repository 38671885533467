import request from '@/utils/requestv2'
export function houseColumnarPic(params) {
  return request({
    url: '/admin/dashboard/houseColumnarPic',
    method: 'post',
    params,
  })
}
export function totalListAPI(params) {
  return request({
    url: '/admin/dashboard/total',
    method: 'post',
    params,
  })
}
export function moneyBrokenLine(params) {
  return request({
    url: '/admin/dashboard/houseBrokenline',
    method: 'post',
    params,
  })
}

export function moneyYearBrokenLine(params) {
  return request({
    url: '/admin/dashboard/houseYearBrokenline',
    method: 'post',
    params,
  })
}
export function shopYearBrokenLine(params) {
  return request({
    url: '/admin/dashboard/shopYearBrokenLine',
    method: 'post',
    params,
  })
}

export function shopBrokenLine(params) {
  return request({
    url: '/admin/dashboard/shopBrokenLine',
    method: 'post',
    params,
  })
}
export function moneyColumnarPic(params) {
  return request({
    url: '/admin/dashboard/moneyColumnarPic',
    method: 'post',
    params,
  })
}
export function pieChart(params) {
  return request({
    url: '/admin/dashboard/pieChart',
    method: 'post',
    params,
  })
}
export function houseDistribute(params) {
  return request({
    url: '/admin/dashboard/houseDistribute',
    method: 'post',
    params,
  })
}
