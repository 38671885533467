import request from '@/utils/requestv2'

// 获取字典列表
export function getDictsAPI(data) {
  return request({
    url: '/admin/sys/dicts',
    method: 'get',
    params: data,
  })
}
// 保存字典
export function addDictsAPI(data) {
  return request({
    url: '/admin/sys/dicts',
    method: 'post',
    data: data,
  })
}
// 修改字典
export function updateDictsAPI(data) {
  return request({
    url: '/admin/sys/dicts',
    method: 'put',
    data: data,
  })
}
// 删除字典
export function deleteDictsAPI(data) {
  return request({
    url: `/admin/sys/dicts/${data}`,
    method: 'delete',
  })
}

// 获取字典类型列表
export function getTypesAPI(data) {
  return request({
    url: '/admin/sys/dict/types',
    method: 'get',
    params: data,
  })
}
// 保存字典类型
export function addTypesAPI(data) {
  return request({
    url: '/admin/sys/dict/types',
    method: 'post',
    data: data,
  })
} // 修改字典类型
export function updateTypesAPI(data) {
  return request({
    url: '/admin/sys/dict/types',
    method: 'put',
    data: data,
  })
} // 删除字典类型
export function deleteTypesAPI(data) {
  return request({
    url: `/admin/sys/dict/types/${data}`,
    method: 'delete',
  })
}
