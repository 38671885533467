var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            action: _vm.domain,
            "before-upload": _vm.beforeUpload,
            data: _vm.QiniuData,
            "file-list": _vm.fileList,
            limit: _vm.number,
            "list-type": _vm.fileType,
            multiple: _vm.multiple,
            "on-change": _vm.handleChange,
            "on-exceed": _vm.onExceed,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleImageSuccess,
          },
        },
        [
          _vm.fileType !== "text"
            ? _c("i", { staticClass: "el-icon-upload" })
            : _c("el-button", { attrs: { type: "text" } }, [
                _vm._v("点击上传"),
              ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "查看大图",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { alt: "", src: _vm.dialogImageUrl, width: "100%" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }