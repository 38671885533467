import request from '@/utils/requestv2'

// 1智能电表抄表记录
export async function smartDeviceMeterRecords(data) {
  return request({
    url: '/smart-device-meter-records',
    method: 'get',
    params: data,
  })
}
// 1智能电表抄表记录
export async function smartDevice(data) {
  return request({
    url: '/smart-device',
    method: 'get',
    params: data,
  })
}
// 房源设备绑定记录列表
export async function bindLogs(data, id) {
  return request({
    url: `/smart-device/${id}/bind-logs`,
    method: 'get',
    params: data,
  })
}

// 根据小区ID查询楼栋
export async function findBuildingByCommunityId(data) {
  return request({
    url: '/admin/building/findBuildingByCommunityId',
    method: 'get',
    params: data,
  })
}
