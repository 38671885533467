import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function articleCategoryList(data) {
  return request({
    url: '/admin/article/category/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 文章分类删除
export async function deleteArticleCategory(data) {
  return request({
    url: '/admin/article/category/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增文章分类
export async function addArticleCategory(data) {
  return request({
    url: '/admin/article/category/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改文章分类
export function editArticleCategory(data) {
  return request({
    url: '/admin/article/category/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 置顶切换
export function toggleIsTop(data) {
  return request({
    url: '/admin/article/category/toggleIsTop',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 发布切换
export function togglePutAway(data) {
  return request({
    url: '/admin/article/category/togglePutAway',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 查询所有的文章分类
export function categoryOptions() {
  return request({
    url: '/admin/article/category/categoryOptions',
    method: 'post',
  })
}
