import request from '@/utils/requestv2'

// 海报头图列表
export function getPosterImg(params) {
  return request({
    url: '/zss-poster-img',
    method: 'get',
    params: params,
  })
}
// 保存海报头图
export function addPosterImg(params) {
  return request({
    url: '/zss-poster-img',
    method: 'post',
    data: params,
  })
}
// 修改海报头图
export function updatePosterImg(params) {
  return request({
    url: '/zss-poster-img',
    method: 'put',
    data: params,
  })
}
// 删除海报头图
export function delPosterImg(params) {
  return request({
    url: `/zss-poster-img/${params}`,
    method: 'delete',
  })
}
// 海报配置获取接口
export function getPosterConfig(params) {
  return request({
    url: `/zss-poster-config`,
    method: 'get',
    params: params,
  })
}
// 海报配置修改接口
export function updatePosterConfig(params) {
  return request({
    url: `/zss-poster-config`,
    method: 'put',
    data: params,
  })
}
