import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function subjectList(data) {
  return request({
    url: '/admin/bill/subject/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function initRank() {
  return request({
    url: '/admin/bill/subject/initRank',
    method: 'get',
  })
}

export async function addSubject(data) {
  return request({
    url: '/admin/bill/subject/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function editSubject(data) {
  return request({
    url: '/admin/bill/subject/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function moveUpward(data) {
  return request({
    url: '/admin/bill/subject/up',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function moveDown(data) {
  return request({
    url: '/admin/bill/subject/down',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function deleteSubject(data) {
  return request({
    url: '/admin/bill/subject/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
