import request from '@/utils/requestiot'

// 查询设备实时数据
export async function shopSmartDevice(data) {
  return request({
    url: '/shop/smart/device/query',
    method: 'get',
    params: data,
  })
}
