import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 反馈列表
export async function feedBackList(data) {
  return request({
    url: '/admin/member/feedback/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除
export async function deleteBatch(data) {
  return request({
    url: '/admin/member/feedback/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
