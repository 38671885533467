import request from '@/utils/requestv2'
import qs from 'qs'

// 渠道佣金默认配置详情
export function getChannelCommissionDefaultConfig() {
  return request({
    url: '/admin/channel/commissionConfig/defaultConfig',
    method: 'get',
  })
}

// 渠道佣金默认配置保存
export function updateChannelCommissionDefaultConfig(data) {
  return request({
    url: '/admin/channel/commissionConfig/saveDefault',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 渠道佣金配置详情
export function getChannelCommissionConfigDetail(data) {
  return request({
    url: '/admin/channel/commissionConfig/detail',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 渠道佣金配置保存
export function updateChannelCommissionConfig(params) {
  return request({
    url: '/admin/channel/commissionConfig/save',
    method: 'post',
    data: qs.stringify(params),
  })
}
