import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 商户结算账号信息列表（分页）
export async function refundlogList(data) {
  return request({
    url: '/admin/financial/refund/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
