/**
 * @description 导出网络配置
 **/
console.log(process.env, '2222')
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  // baseURL:
  //   process.env.NODE_ENV === 'development'
  //     ? '/'
  //     : 'http://zss-test-api-admin.lingxiaomao.com',
  // prod = 'https://api.service.zushengshi.com'
  // test: http://zss-dev-admin-api.lingmaosoft.cn
  // dev: https://zss-dev-api.lingmaosoft.cn 1.2专用

  // 新api环境管理后台专用
  // dev http://zss-dev-api-admin.lingmaosoft.cn
  // test http://zss-test-api-admin.lingxiaomao.com

  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  // contentType: 'application/json;charset=UTF-8',
  baseURLV1: process.env.VUE_APP_BASE_API_v1,
  baseURLV2: process.env.VUE_APP_BASE_API_v2,
  baseURLIot: process.env.VUE_APP_BASE_API_iot,
  //第三方接口key
  GD_API_WEB_SERVER_KEY: 'eddbb23dac9aeab00c173038752d24ae',
  GD_API_WEB_JS_KEY: '9b4253705c6ec1d9f2854515eceb03da',
  GD_HTTPS_URL: 'https://restapi.amap.com',

  contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 10000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
}
