import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 配置列表
export async function configList(data) {
  return request({
    url: '/admin/sys/config/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增
export async function configSave(data) {
  return request({
    url: '/admin/sys/config/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除
export async function configDelete(data) {
  return request({
    url: '/admin/sys/config/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 编辑
export async function configUpdate(data) {
  return request({
    url: '/admin/sys/config/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 配置详情
export async function configDetail(data) {
  return request({
    url: '/admin/sys/config/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
