import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 账单明细列表列表（分页）
export async function sheetList(data) {
  return request({
    url: '/admin/bill/sheel/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

//getBillDetailPage
export async function getBillDetailPage(data) {
  return request({
    url: '/admin/bill/sheel/getBillDetailPage',
    method: 'post',
    data: stringify({ ...data }),
  })
}
