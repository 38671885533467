import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function shopActionLogList(data) {
  return request({
    url: '/admin/shop/action/log/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
export function queryDetail(data) {
  return request({
    url: '/admin/shop/action/log/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
