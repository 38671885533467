<template>
  <div>
    <el-pagination
      background
      :current-page.sync="currentPage"
      :layout="layout"
      :page-size.sync="pageSize"
      :page-sizes="[10, 20, 30, 50]"
      :total="totalCount"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  export default {
    name: 'Pagination',
    props: {
      totalCount: {
        required: true,
        type: Number,
        default: 0,
      },
      page: {
        type: Number,
        default: 1,
      },
      limit: {
        type: Number,
        default: 20,
      },
      pageSizes: {
        type: Array,
        default() {
          return [10, 20, 30, 50]
        },
      },
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next',
      },
    },
    computed: {
      currentPage: {
        get() {
          return this.page
        },
        set(val) {
          // console.log(val)
          this.$emit('update:page', val)
        },
      },
      pageSize: {
        get() {
          console.log(this.limit, '2222222222222222222')
          return this.limit
        },
        set(val) {
          this.$emit('update:limit', val)
        },
      },
    },
    methods: {
      handleSizeChange(val) {
        // 当前页面limit限制数
        console.log(val)
        this.$emit('pagination', { page: this.currentPage, limit: val }) // 因为后台接口currentPage是从0开始的
      },
      handleCurrentChange(val) {
        // 当前页码数
        //   console.log(val)
        this.$emit('pagination', { page: val - 1, limit: this.pageSize })
      },
    },
  }
</script>

<style scoped>
  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
</style>
