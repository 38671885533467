import request from '@/utils/requestv2'

export function getSmsConfig(params) {
  return request({
    url: '/admin/sms/config',
    method: 'get',
    params,
  })
}
export function updateSmsConfig(params) {
  return request({
    url: '/admin/sms/config',
    method: 'put',
    data: params,
  })
}
