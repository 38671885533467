import request from '@/utils/requestv2'

export function getProduct(params) {
  return request({
    url: '/admin/sms/product',
    method: 'get',
    params,
  })
}
export function addProduct(params) {
  return request({
    url: '/admin/sms/product',
    method: 'post',
    data: params,
  })
}
export function updateProduct(params) {
  return request({
    url: '/admin/sms/product',
    method: 'put',
    data: params,
  })
}
export function deleteProduct(ids) {
  return request({
    url: `/admin/sms/product/${ids}`,
    method: 'delete',
  })
}
