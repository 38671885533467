/**
 * http请求公共方法封装
 * @author iron.guo
 * @since 2022-07-23
 * getAction, deleteAction, putAction, postAction
 */
import request from '@/utils/requestv2'
import { stringify } from 'qs'
//post
export async function postAction(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: stringify({ ...parameter }),
  })
}

//put
export async function putAction(url, parameter) {
  return request({
    url: url,
    method: 'put',
    data: stringify({ ...parameter }),
  })
}

//get
export function getAction(url, params) {
  return request({
    url: 'url',
    method: 'get',
    params,
  })
}

//deleteAction
export function deleteAction(url, parameter) {
  return request({
    url: url,
    method: 'delete',
    data: stringify({ ...parameter }),
  })
}
