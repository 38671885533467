import request from '@/utils/requestv2'
import { stringify } from 'qs'

//import { stringify } from 'qs'

export async function groupList() {
  return request({
    url: '/admin/feature/group/list',
    method: 'post',
  })
}

export async function featureList(data) {
  return request({
    url: '/admin/feature/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function addTagGroup(data) {
  return request({
    url: '/admin/feature/group/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function deleteGroupTag(params) {
  return request({
    url: '/admin/feature/group/delete',
    method: 'delete',
    params,
  })
}
export async function addTag(data) {
  return request({
    url: '/admin/feature/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function editTag(data) {
  return request({
    url: '/admin/feature/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function delTag(data) {
  return request({
    url: '/admin/feature/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function setHot(data) {
  return request({
    url: '/admin/house/publish/setHot',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function publishDetail(data) {
  return request({
    url: '/admin/house/publish/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function passOrRefuse(data) {
  return request({
    url: '/admin/house/publish/passOrRefuse',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function batchPassOrRefuse(data) {
  return request({
    url: '/admin/house/publish/batchPassOrRefuse',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function queryTagDetail(data) {
  return request({
    url: '/admin/feature/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
