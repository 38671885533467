import request from '@/utils/requestv2'
import { stringify } from 'qs'

/**
 * 设备型号下拉
 */
export async function SelectDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/*
 * 新增设备型号
 */
export async function SaveDeviceModel(data) {
  console.log(stringify({ ...data }))
  return request({
    url: '/admin/smart/device/model/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 修改设备型号
 */
export async function UpdateDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除设备型号
 */
export async function DeleteDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 删除多个设备型号
 */
export async function DeleteMultipleDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/batchDelete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备型号分页
 */
export async function PageDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 设备型号详情
 */
export async function DetailDeviceModel(data) {
  return request({
    url: '/admin/smart/device/model/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
