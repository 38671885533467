import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function helpInformationList(data) {
  return request({
    url: '/admin/help/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 帮助信息删除
export async function deleteHelpInformation(data) {
  return request({
    url: '/admin/help/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增帮助信息
export async function addHelpInformation(data) {
  return request({
    url: '/admin/help/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改帮助信息
export function editHelpInformation(data) {
  return request({
    url: '/admin/help/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改帮助信息
export function getHelpCategory(data) {
  return request({
    url: '/admin/help/category/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 获得帮助信息
export function getHelpInformationDetail(data) {
  return request({
    url: '/admin/help/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
