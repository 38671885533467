// 通用接口
import request from '@/utils/requestv2'
import qs from 'qs'

// 获取七牛上传Token
export function getUploadToken(params) {
  return request({
    url: '/admin/common/getUploadToken',
    method: 'post',
    data: qs.stringify(params),
  })
}
// 省市区三级联动
export function getRegion(parentCode = 0) {
  return request({
    url: '/admin/region/queryRegion',
    method: 'post',
    data: qs.stringify({
      parentCode,
    }),
  })
}
