import request from '@/utils/requestv2'
import { stringify } from 'qs'

/**
 * 业主下拉列表
 */
export async function SelectMerchant(data) {
  return request({
    url: '/admin/smart/device/brand/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}
/**
 * 操作员设备绑定记录列表
 */
export async function PageMemberSmartDeviceBindLog(data) {
  return request({
    url: '/admin/smart/device/bindLog/pageMemberSmartDeviceBindLog',
    method: 'post',
    data: stringify({ ...data }),
  })
}
