<template><quill-editor v-model="myValue" :options="options" /></template>

<script>
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  // import Quill from 'quill'
  import { quillEditor } from 'vue-quill-editor'
  // import ImageResize from 'quill-image-resize-module' //添加
  // Quill.register('modules/imageResize', ImageResize) //添加
  export default {
    name: 'JEditor',
    components: {
      quillEditor,
    },
    props: {
      value: {
        type: String,
        default: '',
        required: false,
      },
      triggerChange: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        myValue: this.value,
        options: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['link', 'image', 'vab-upload-image'],
              ],
              handlers: {
                'vab-upload-image': () => {
                  this.$baseConfirm(
                    '演示环境未使用真实文件服务器，故图片上传回显不会生效，开发时请修改为正式文件服务器地址',
                    '开发注意事项！！！',
                    () => {
                      this.$refs['vabUpload'].handleShow()
                    },
                    () => {
                      this.handleAddImg()
                    },
                    '模拟打开文件上传',
                    '模拟添加一张文件服务器图片'
                  )
                },
              },
            },
            // imageResize: {
            //   //控制图片编辑的，实现功能就是这一段代码
            //   displayStyles: {
            //     backgroundColor: 'black',
            //     border: 'none',
            //     color: 'white',
            //   },
            //   modules: ['Resize', 'DisplaySize', 'Toolbar'],
            // },
          },
          placeholder: '内容....',
          readOnly: false,
        },
      }
    },
    watch: {
      value(newValue) {
        this.myValue = newValue == null ? '' : newValue
      },
      myValue(newValue) {
        if (this.triggerChange) {
          this.$emit('change', newValue)
        } else {
          this.$emit('input', newValue)
        }
      },
    },
    created() {},
    methods: {
      clear() {
        this.myValue = ''
      },
    },
  }
</script>

<style scoped>
  /deep/ .ql-container {
    min-height: 192px;
  }
</style>
