import request from '@/utils/requestv2'
// 获取角色列表
export function getRoleAPI(data) {
  return request({
    url: '/admin/sys/roles',
    method: 'get',
    params: data,
  })
}
// 保存角色
export function addRoleAPI(data) {
  return request({
    url: '/admin/sys/roles',
    method: 'post',
    data: data,
  })
}
// 修改角色
export function pudateRoleAPI(data) {
  return request({
    url: '/admin/sys/roles',
    method: 'put',
    data: data,
  })
}
// 删除角色
export function deleteRoleAPI(data) {
  return request({
    url: `/admin/sys/roles/${data}`,
    method: 'delete',
  })
}
// 获取角色权限列表
export function getMenusAPI(data) {
  return request({
    url: `/admin/sys/roles/${data}/menus`,
    method: 'get',
  })
}
// 修改角色权限列表
export function updateRoleAPI(data, id) {
  return request({
    url: `/admin/sys/roles/${id}/menus`,
    method: 'put',
    data: data,
  })
}
