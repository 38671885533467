import request from '@/utils/requestv2'

export function bannerList(params) {
  return request({
    url: '/admin/banner/page',
    method: 'post',
    params,
  })
}

export function bannerDel(params) {
  return request({
    url: '/admin/banner/delete',
    method: 'post',
    params,
  })
}

export function bannerAdd(params) {
  return request({
    url: '/admin/banner/add',
    method: 'post',
    params,
  })
}

export function bannerUpdate(params) {
  return request({
    url: '/admin/banner/update',
    method: 'post',
    params,
  })
}

export function wxshareList(params) {
  return request({
    url: '/admin/wx/share/page',
    method: 'post',
    params,
  })
}

export function wxshareDel(params) {
  return request({
    url: '/admin/wx/share/delete',
    method: 'post',
    params,
  })
}

export function wxshareAdd(params) {
  return request({
    url: '/admin/wx/share/add',
    method: 'post',
    params,
  })
}

export function wxshareUpdate(params) {
  return request({
    url: '/admin/wx/share/update',
    method: 'post',
    params,
  })
}

export function chargeList(params) {
  return request({
    url: '/admin/global/fee/config/page',
    method: 'post',
    params,
  })
}

export function chargeDel(params) {
  return request({
    url: '/admin/global/fee/config/delete',
    method: 'post',
    params,
  })
}

export function chargeAdd(params) {
  return request({
    url: '/admin/global/fee/config/add',
    method: 'post',
    params,
  })
}

export function chargeUpdate(params) {
  return request({
    url: '/admin/global/fee/config/update',
    method: 'post',
    params,
  })
}

export function billList(params) {
  return request({
    url: '/admin/shop/bill/subject/page',
    method: 'post',
    params,
  })
}

export function billDel(params) {
  return request({
    url: '/admin/shop/bill/subject/delete',
    method: 'post',
    params,
  })
}

export function billAdd(params) {
  return request({
    url: '/admin/shop/bill/subject',
    method: 'post',
    params,
  })
}

export function billUpdate(params) {
  return request({
    url: '/admin/shop/bill/subject/update',
    method: 'post',
    params,
  })
}

export function billUpdateChecked(params) {
  return request({
    url: '/admin/shop/bill/subject/updateChecked',
    method: 'post',
    params,
  })
}

export function shopList() {
  return request({
    //url: '/admin/merchant/shopList/page',
    url: '/admin/shopList/page',
    method: 'post',
    params: { pageSize: 100000 },
  })
}

export function shopApplyList() {
  return request({
    url: '/admin/merchant/shopList/page',
    method: 'post',
    params: { pageSize: 100000 },
  })
}

export function shopListFilter() {
  return request({
    url: '/admin/merchant/shopList/pageFilter',
    method: 'post',
    params: { pageSize: 100000 },
  })
}

export function billTypeList() {
  return request({
    url: '/admin/bill/subject/page',
    method: 'post',
    params: { pageSize: 100000 },
  })
}

export function paramSettingList(params) {
  return request({
    url: '/admin/global/param/setting/page',
    method: 'post',
    params,
  })
}

export function getParamMap(params) {
  return request({
    url: '/admin/global/param/setting/getParamMap',
    method: 'post',
    params,
  })
}

export function paramSettingAdd(params) {
  return request({
    url: '/admin/global/param/setting/add',
    method: 'post',
    params,
  })
}

export function paramSettingDetail(params) {
  return request({
    url: '/admin/global/param/setting/detail',
    method: 'post',
    params,
  })
}

export function paramSettingUpdate(params) {
  return request({
    url: '/admin/global/param/setting/update',
    method: 'post',
    params,
  })
}
