import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function walletList(data) {
  return request({
    url: '/admin/financial/preferential/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function rechargeSave(data) {
  return request({
    url: '/admin/financial/preferential/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function rechargeupdate(data) {
  return request({
    url: '/admin/financial/preferential/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export async function rechargeRemove(data) {
  return request({
    url: '/admin/financial/preferential/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}
