import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 商户结算账号信息列表（分页）
export async function shopbankList(data) {
  return request({
    url: '/admin/merchant/shopbank/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 获取商户默认配置
export async function detailCostFeeConfig(data) {
  return request({
    url: '/admin/merchant/shopList/detailCostFeeConfig',
    method: 'post',
    data: stringify({ ...data }),
  })
}
