import request from '@/utils/requestv2'
import qs from 'qs'

// 渠道商申请记录列表
export function getChannelApplyPage(data) {
  return request({
    url: '/admin/channel/apply/page',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 渠道商申请记录详情
export function getChannelApplyDetail(id) {
  return request({
    url: '/admin/channel/apply/detail',
    method: 'get',
    params: { id },
  })
}

// 渠道商申请记录审核
export function postChannelApplyAudit(params) {
  return request({
    url: '/admin/channel/apply/audit',
    method: 'post',
    data: qs.stringify(params),
  })
}

// 渠道商申请记录批量审核
export function postChannelApplyAuditBatch(params) {
  return request({
    url: '/admin/channel/apply/batchAudit',
    method: 'post',
    data: qs.stringify(params),
  })
}
