import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 账单列表（分页）
export async function getList(data) {
  return request({
    url: '/admin/bill/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 账单详情
export function getDetail(data) {
  return request({
    url: '/admin/bill/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}
