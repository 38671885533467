var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.onClose,
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width,
          },
        },
        [
          _c(
            "div",
            { staticClass: "container", style: { height: _vm.height + "px" } },
            [_vm._t("content")],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.title == "新增订单" ||
              _vm.title == "新增设备类型" ||
              _vm.title == "新增设备品牌" ||
              _vm.title == "新增设备型号"
                ? _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(" 取 消 "),
                  ])
                : _vm._e(),
              _vm.title == "新增订单" ||
              _vm.title == "新增设备类型" ||
              _vm.title == "新增设备品牌" ||
              _vm.title == "新增设备型号"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }