import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function userList(data) {
  return request({
    url: '/admin/sys/user/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增用户
export async function addUser(data) {
  return request({
    url: '/admin/sys/user/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改用户
export function editUser(data) {
  return request({
    url: '/admin/sys/user/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除用户
export async function deleteUser(data) {
  return request({
    url: '/admin/sys/user/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 设置角色
export async function setUser(data) {
  return request({
    url: '/admin/sys/user/saveRole',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 关联角色列表
export async function getRole(data) {
  return request({
    url: '/admin/sys/user/getRole',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 获取登录用户信息
export async function getUserInfo(data) {
  return request({
    url: '/admin/userinfo',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 给用户重置密码
export async function resetPassword(data) {
  return request({
    url: '/admin/sys/user/resetPassword',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改自己的密码（需登录）
export async function updatePassword(data) {
  return request({
    url: '/admin/resetPassword',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 省市区三级总数据接口
export async function regionList(data) {
  return request({
    url: '/admin/region/regionAll',
    method: 'post',
    data: stringify({ ...data }),
  })
}
