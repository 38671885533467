import request from '@/utils/requestv2'

// 商户列表
export function adminShop(params) {
  return request({
    url: '/admin/shop',
    method: 'get',
    params: params,
  })
}
// 商户结算账户列表
export function shopBank(params) {
  return request({
    url: '/admin/shop/bank',
    method: 'get',
    params: params,
  })
}
// 获取支付渠道
export function payChannel(params) {
  return request({
    url: '/admin/shop/get-pay-channel',
    method: 'get',
    params: params,
  })
}
