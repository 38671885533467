import request from '@/utils/requestv2'

// 获取树形菜单
export function getMenuAPI(data) {
  return request({
    url: '/admin/sys/menus/tree',
    method: 'get',
    params: data,
  })
}
// 保存菜单
export function addMenuAPI(data) {
  return request({
    url: '/admin/sys/menus',
    method: 'post',
    data: data,
  })
}
// 修改菜单
export function putMenuAPI(data) {
  return request({
    url: '/admin/sys/menus',
    method: 'put',
    data: data,
  })
}
// 修改菜单
export function deleteMenuAPI(id) {
  return request({
    url: `/admin/sys/menus/${id}`,
    method: 'delete',
  })
}
