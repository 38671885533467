import request from '@/utils/requestv2'
import { stringify } from 'qs'
import requestV2 from '@/utils/requestv2'

// 房源信息列表（分页）
export async function buildList(data) {
  return request({
    url: '/admin/house/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除房源信息
export async function deleteBuild(data) {
  return request({
    url: '/admin/house/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 楼栋分页列表
export async function buildingList(data) {
  return request({
    url: '/admin/house/building/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 楼栋信息详情
export async function buildingDetail(data) {
  return request({
    url: '/admin/house/building/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 小区列表
export async function communityList(data) {
  return request({
    url: '/admin/house/community/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 合同列表
export async function contractList(data) {
  return request({
    url: '/admin/house/contract/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 水电表列表
export async function meterList(data) {
  return request({
    url: '/admin/house/meter/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 房源合同详情
export async function contractDetail(data) {
  return request({
    url: '/admin/house/contract/detail',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 入住人列表
export async function housePeopleDetail(data) {
  return request({
    url: '/admin/house/contract/housePeople',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 详情页账单
export async function contractBill(data) {
  return request({
    url: '/admin/bill/contractBillPage',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 详情页账单
export async function costTotal(data) {
  return request({
    url: '/admin/bill/costTotal',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 合同详情页账单详情
export async function findContractBillDetailList(data) {
  return request({
    url: '/admin/bill/sheel/findContractBillDetailList',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 账单服务费用
export async function billServeFee(data) {
  return request({
    url: '/admin/bill/billServeFee',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 初始化楼栋经纬度
export async function buildingLongitudeAndLatitudeInitApi() {
  return requestV2({
    url: '/admin/building/buildingLongitudeAndLatitudeInit',
    method: 'get',
  })
}
// 初始化楼栋经纬度
export async function getShopBillSubject(params) {
  return requestV2({
    url: '/admin/bill/subject',
    method: 'get',
    params,
  })
}
// 锁钥匙添加
export async function addLockKey(params) {
  return requestV2({
    url: '/admin/lock/key',
    method: 'post',
    data:params,
  })
}
// 锁钥匙分页列表
export async function getLockKey(params) {
  return requestV2({
    url: '/admin/lock/key',
    method: 'get',
    params,
  })
}
// 锁钥匙删除
export async function deleteLockKey(id) {
  return requestV2({
    url: `/admin/lock/key/${id}`,
    method: 'delete',
  })
}
// 锁钥匙修改
export async function updateLockKey(data) {
  return requestV2({
    url: `/admin/lock/key`,
    method: 'put',
    data
  })
}
// 远程开锁
export async function remoteLockKey(id) {
  return requestV2({
    url: `/admin/lock/key/${id}`,
    method: 'put',
  })
}
// 开锁记录
export async function getOpenLockRecord(params) {
  return requestV2({
    url: `/admin/open/lock/record`,
    method: 'get',
    params
  })
}
// 授权记录
export async function getLockMemberRelation(params) {
  return requestV2({
    url: `/admin/lock/member/relation`,
    method: 'get',
    params
  })
}
// 授权用户
export async function addLockMemberRelation(data) {
  return requestV2({
    url: `/admin/lock/member/relation`,
    method: 'post',
    data
  })
}
// 入住人列表
export async function housePeopleHousePeople(params) {
  return requestV2({
    url: `/admin/house/people/housePeople`,
    method: 'get',
    params
  })
}