import request from '@/utils/requestv2'
import { stringify } from 'qs'

export async function articleList(data) {
  return request({
    url: '/admin/article/info/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 文章删除
export async function deleteArticle(data) {
  return request({
    url: '/admin/article/info/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增文章
export async function addArticle(data) {
  return request({
    url: '/admin/article/info/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 修改文章
export function editArticle(data) {
  return request({
    url: '/admin/article/info/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 置顶切换
export function toggleIsTop(data) {
  return request({
    url: '/admin/article/info/toggleIsTop',
    method: 'post',
    data: stringify({ ...data }),
  })
}
// 发布切换
export function togglePutAway(data) {
  return request({
    url: '/admin/article/info/togglePutAway',
    method: 'post',
    data: stringify({ ...data }),
  })
}
