var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "destroy-on-close": _vm.destroy,
            "modal-append-to-body": false,
            title: _vm.title,
            top: _vm.top,
            visible: _vm.visible,
            width: _vm.width,
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "container", style: { height: _vm.height + "px" } },
            [_vm._t("content")],
            2
          ),
          _vm.showfoot
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.showfoot
                    ? _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("取 消"),
                      ])
                    : _vm._e(),
                  _vm.showfoot
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleConfirm },
                        },
                        [_vm._v(" " + _vm._s(_vm.confirmBntText) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }